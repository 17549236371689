// src/services/axiosConfig.js
import axios from "axios";
if (process.env.REACT_APP_MAINTENANCE === "true") {
  localStorage.removeItem("keddyToken");
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3000",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // If you need to include cookies in the request
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("keddyToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const navigateToLogin = () => {
  window.dispatchEvent(new CustomEvent("navigateToLogin"));
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      localStorage.removeItem("keddyToken");
      navigateToLogin();
    }
  }
);

export default axiosInstance;
